import React from 'react';
import Layout from '@components/common/Layout';
import Seo from '@components/common/Seo';

const IndexPage = () => (
  <Layout language="fr" segmentId={process.env.GATSBY_SEGMENT_SCREENING_2_PRODUCTION_SE as string}>
    <Seo
      title="Screening"
      description="Screening"
      lang="fr"
    />

    <div className="container container--wide margin-top--md margin-bottom--md">
      <div className="container container--lg margin-bottom--md flex flex--center">
        <img
          src="https://assets.jointacademy.com/logos/jointacademy_logo_pos.svg"
          alt="Joint Academy Logo"
          className="screening-test__logo"
        />
      </div>

      <div className="text--component ">
        <p className="text--center">
          Joint Academy est actuellement indisponible.
          <br />
          Nous
          travaillons activement à l’amélioration de notre plateforme.
          <br />
          Si vous avez des questions n’hésitez pas à nous contacter à
          {' '}
          <a href="mailto:info@jointacademy.fr">info@jointacademy.fr</a>
          .
          <br />
          Nous espérons vous retrouver bientôt pour vous
          accompagner dans votre parcours de gestion des douleurs articulaires !
        </p>
      </div>
    </div>

    {/* <IconCardContainer title="Quelle articulation vous pose le plus de problème ?">
      <IconCard
        title="Épaule"
        imageSrc={ImageShoulder}
        url="/fr/fr/epaule/"
      />
      <IconCard
        title="Hanche"
        imageSrc={ImageHip}
        url="/fr/fr/hanche/"
      />
      <IconCard
        title="Genou"
        imageSrc={ImageKnee}
        url="/fr/fr/genou/"
      />
      <IconCard
        title="Mal au dos"
        imageSrc={ImageBack}
        url="/fr/fr/mal-de-dos/"
      />
    </IconCardContainer> */}
  </Layout>
);

export default IndexPage;
